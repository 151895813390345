
import filters from "~/mixins/filters";

export default {
	mixins: [filters],
	props: {
		isForm: {
			type: Boolean,
			default: false
		},
		vin: String,
		price: Number
	}
}
